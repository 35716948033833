import React, { Fragment } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

export const RadioField = ({
  name,
  fieldId,
  onChange,
  valid = true,
  options,
  checked,
  align = "col",
  labelName = "",
  sx,
}: IText) => {
  return (
    <FormControl fullWidth error={!valid} sx={sx}>
      <FormLabel id="demo-row-radio-buttons-group-label">{labelName}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue=""
        name="radio-buttons-group"
        onChange={onChange}
        value={checked}
        row={align === "row"}
      >
        {options.map((option: any) => (
          <Fragment key={option.value}>
            <FormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          </Fragment>
        ))}
      </RadioGroup>
      {!valid && <FormHelperText>Required</FormHelperText>}
    </FormControl>
  );
};

export interface IText {
  name: string;
  fieldId: string;
  onChange: any;
  valid?: any;
  options: any;
  checked?: any;
  labelName?: string;
  align?: "col" | "row";
  sx?: any;
}
