export enum IconTypes {
  Search = "search",
  Megaphone = "campaign",
  Menu = "menu",
  Cancel = "cancel",
  Down = "expand_more",
  ChevronLeft = "chevron_left",
  ChevronRight = "chevron_right",
  Email = "email",
  Sms = "textsms",
  Voice = "graphic_eq",
  SocialMedia = "share",
  Dashboard = "dashboard",
  Web = "web",
  Users = "people",
  PermIdentity = "perm_identity",
  Calendar = "event",
  Admin = "manage_accounts",
  Back = "arrow_circle_left",
  Edit = "edit",
  Copy = "content_copy",
  Delete = "delete",
  Transfer = "arrow_right_alt",
  Filter = "filter_alt",
  filterAltOff = "filter_alt_off",
  FilterClose = "filter_alt_off",
  Eye = "visibility",
  Refresh = "refresh",
  ListView = "view_list",
  GridView = "grid_view",
  CheckBox = "check_box_outline_blank",
  CheckBoxFilled = "check_box",
  Checkmark = "check_circle_outline",
  Left = "keyboard_arrow_left",
  Right = "keyboard_arrow_right",
  DoubleArrows = "double_arrow",
  UpArrow = "keyboard_arrow_up",
  DownArrow = "keyboard_arrow_down",
  Recurring = "schedule",
  Save = "save",
  Point = "touch_app",
  Preview = "find_in_page",
  ArrowDropDown = "arrow_drop_down",
  ArrowDropUp = "arrow_drop_up",
  KeyboardDoubleArrowLeft = "keyboard_double_arrow_left",
  KeyboardDoubleArrowRight = "keyboard_double_arrow_right",
  KeyboardDoubleArrowUp = "keyboard_double_arrow_up",
  KeyboardDoubleArrowDown = "keyboard_double_arrow_down",
  Info = "info",
  Undo = "undo",
  Redo = "redo",
  Add = "add",
  Remove = "remove",
  ZoomIn = "zoom_in",
  FileUpload = "file_upload",
  FileDownload = "file_download",
  Download = "download",
  VisibilityOff = "visibility_off",
  PlayCircleOutline = "play_circle_outline",
  PauseCircleOutline = "pause_circle_outline",
  Fev = "favorite",
  Unfev = "favorite_border",
  Clear = "clear",
  Crop = "crop",
  ThumbUp = "thumb_up",
  ChatBubbleOutline = "chat_bubble_outline",
  Ballot = "ballot",
  Style = "style",
  Tree = "account_tree",
  Collections = "collections",
  Settings = "settings",
  Bookmark = "bookmark_border",
  Send = "send",
  Repeat = "repeat",
  Link = "link",
  Morevert = "more_vert",
  Phonemsg = "perm_phone_msg",
  Locationpin = "person_pin",
  Person = "person",
  MoveUp = "move_up",
  Resize = "aspect_ratio",
  Description = "description",
  OpenNew = "open_in_new",
  Openbrowser = "open_in_browser",
  Zip = "folder_zip",
  Circleout = "add_circle_outline",
  Circleremove = "remove_circle_outline",
  Radiocheck = "radio_button_checked",
  Archive = "archive",
  Organization = "corporate_fare",
  CalendarMonth = "calendar_month",
  AccountCircle = "account_circle",
  Globe = "public",
  BarChart = "bar_chart",
  ArrowForwardIos = "arrow_forward_ios",
  ArrowBackIos = "arrow_back_ios",
  Face = "face",
  TaskAlt = "task_alt",
  ForwardToInbox = "forward_to_inbox",
  EmailReply = "reply",
  EmailForward = "shortcut",
  TrendingUp = "trending_up",
  Assignment = "assignment",
  AssignmentInd = "assignment_ind",
  Mic = "mic",
  OpenInFull = "open_in_full",
  PermMedia = "perm_media",
  Leaderboard = "leaderboard",
  PersonAddAlt = "person_add_alt",
  PersonRemove = "person_remove",
  ScreenRotation = "screen_rotation",
  Logout = "logout",
  ShoppingCart = "shopping_cart",
  Home = "home",
  RestaurantMenu = "restaurant_menu",
  Widgets = "widgets",
  MenuOpen = "menu_open",
  Code = "code",
  Html = "html",
  Javascript = "javascript",
  Css = "css",
  Source = "source",
  PointOfSale = "point_of_sale",
  Build = "build",
  Class = "class",
  Celebration = "celebration",
  Handshake = "handshake",
  PhoneInTalk = "phone_in_talk",
  Tab = "tab",
  Close = "close",
  FormatBold = "format_bold",
  FormatSize = "format_size",
  FormatUnderlined = "format_underlined",
  FormatLineSpacing = "format_line_spacing",
  HorizontalDistribute = "horizontal_distribute",
  FormatItalic = "format_italic",
  Strikethrough = "strikethrough_s",
  VerticalAlignBottom = "vertical_align_bottom",
  VerticalAlignTop = "vertical_align_top",
  VerticalAlignCenter = "vertical_align_center",
  FormatAlignLeft = "format_align_left",
  FormatAlignCenter = "format_align_center",
  FormatAlignRight = "format_align_right",
  FormatAlignJustify = "format_align_justify",
  FolderCopy = "folder_copy",
  DragIndicator = "drag_indicator",
  DragHandle = "drag_handle",
}
